import {DEBUG} from '@/modules/constants'

export function useFormFns(variables) {
    // we check for empty or null because we want to take into account radio buttons - so can't just use !!value
    const required = value => (value != null && value != '') || 'Required.'
    const requiredNoText = value => !!value || ''
    const min = v => v.length >= 8 || 'Min 8 characters'
    const max = v => !v || v.length <= 16 || 'Max 16 characters'
    const mobileLength = v => !v || v?.replace(/ /g, '').length === 10 || 'Mobile is not valid'
    const postcodeLength = v => !v || v?.length === 4 || 'Postcode is not valid'
    const numbersOnly = v => {
        if (!v || !v.trim() || !isNaN(parseInt(v)) && v >= 0) return true
        return 'Value has to be a number'
    }
    const timeOnly = v => {
        const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/

        if (!v || !v.trim() || timeRegex.test(v)) return true
        return 'Value should be in form hh:mm am|pm'
    }
    const validEmail = v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(v) || 'E-mail is invalid'
    const validRadioGroup = v => {
        return !!v || 'Required.'
    }
    const printError = (error, explicit) => {
        if (DEBUG) console.warn(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] ${error.name}: ${error.message}`)
    }
    const formatRuleFunctions = (rules) => {
        let loadedRules
        if (!rules) return []
        // let's check if we can load the rules
        if (rules && rules.constructor.name.toLowerCase() === 'string') {
            try {
                loadedRules = JSON.parse(rules)
            } catch (e) {
                if (e instanceof SyntaxError) {
                    // printError(e, true)
                    // // this means that the string might not have the string around the rule name - we need this to be able to apply it
                    rules = rules.replace('[', '').replace(']', '')
                    loadedRules = rules.split(',')
                } else {
                    printError(e, false)
                }
            }
        }

        if (!loadedRules) loadedRules = rules
        // if we don't find any rules, we just return an empty array
        if (loadedRules.constructor.name.toLowerCase() !== 'array') return []
        // returning the function that we find from the formsMixins - if it's not in this, add it
        return loadedRules.map(r => typeof r === 'string' ? fns[r] : r)
    }
    const getRulesFromConfig = (config) => {
        return formatRuleFunctions(config.rules || config[':rules'])
    }

    const fns = {
        printError,
        formatRuleFunctions,
        getRulesFromConfig,
        required,
        requiredNoText,
        min,
        max,
        numbersOnly,
        timeOnly,
        validEmail,
        validRadioGroup,
        mobileLength,
        postcodeLength
    }

    return fns
}
