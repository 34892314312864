const buildQuestionTree = (questionSets, isMobile) => {
    const tree = []
    for (let questionSet of questionSets) {
        if (!questionSet.parentQuestionSetId) {
            tree.push(questionSet)
            // let's check if we need to get the correct config
            if (questionSet?.questions?.length) questionSet.questions = mapQuestionConfig(questionSet.questions, isMobile)
            continue
        }
        addNode(tree, questionSet, isMobile)
    }
    return tree
}

const addNode = (tree, node, isMobile) => {
    if (!tree || !tree.length) return

    for (let root of tree) {
        if (root.questionSets.findIndex(qs => qs.questionSetId === node.questionSetId) > -1) {
            // let's check if we need to get the correct config
            if (node?.questions?.length) node.questions = mapQuestionConfig(node.questions, isMobile)
            if (!root.mappedQuestionSets) root.mappedQuestionSets = [node]
            else root.mappedQuestionSets.push(node)
            continue
        }
        addNode(root.mappedQuestionSets, node, isMobile)
    }
    return tree
}

const mapQuestionConfig = (questions, isMobile) => {
    for (let question of questions) {
        // if we are on a mobile, show the mobile config
        if (isMobile && question.configMobile) question.config = question.configMobile
    }

    return questions
}

const getOrBoolExp = (current, list, op = '_in') => {
    if (!current) current = {_or: []}
    current._or.push({
        [op]: list
    })
    return current
}

export {
    buildQuestionTree,
    addNode,
    mapQuestionConfig,
    getOrBoolExp
}
