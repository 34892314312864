<template>
    <v-form
        v-if="!isLoading"
        v-model="isValid"
        ref="form"
        lazy-validation
        @submit.prevent
    >
        <div v-if="forms.length">
            <div
                v-for="(form, idx) of forms"
                :key="`${form.formId}-idx`"
                :class="getFormClasses(form)"
            >
                <div v-if="!hasQuestions(form) && !form.questionSets">
                    <!-- empty div for now until we think about the NPS -->
                </div>
                <div v-else-if="!hasQuestions(form) && form.questionSets">
                    {{emptyQuestionText}}
                </div>
                <div v-else>
                    <Question
                        ref="questionTree"
                        :question-sets="form.questionSets"
                        :questions="form.questions"
                        :disabled="readOnly"
                        :form="form"
                        @question-sets-updated="updateNumberComplete"
                        @save-form="(qs)=>{callSaveForm(idx, qs)}"
                    />
                    <div :class="btnRowClasses" v-if="!readOnly && showBtnRow(idx)">
                        <v-btn
                            v-for="(action, index) of form.actions"
                            :key="`action-${index}`"
                            class="w-full md:w-auto"
                            :class="action.class"
                            :disabled="(loading && isQpa) || saving"
                            v-bind="action.vuetifyAttrs"
                            @click.stop="callEditOrFunction(action)"
                        >
                            {{getButtonText(action)}}
                            <fa-icon icon="chevron-right" class="ml-2 text-white-pure" />
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-form>
    <div class="flex items-center justify-center h-20 w-full" v-else>
        <Loader class="w-12 h-12" />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
// import GET_FORM from '@/graphql/queries/getForm.gql'
import GET_FORM_SET_BY_ID from '@/graphql/queries/getFormSetById.gql'
import GET_QUESTION_IDS_BY_CATEGORY from '@/graphql/queries/getQuestionIdsByCategory.gql'
import GET_QUESTIONS_BY_ID from '@/graphql/queries/getQuestionsById.gql'
import GET_FORM_WITH_QUESTIONS from '@/graphql/queries/getFormWithQuestions.gql'
import GET_ANSWER from '@/graphql/queries/getAnswer.gql'
import GET_ANSWERED_QUESTION_IDS from '@/graphql/queries/getAnsweredQuestionIds.gql'
// import FORM_ANSWER_SUBSCRIPTION from '@/graphql/subscriptions/saveFormAnswer.gql'
// import UPDATE_FORM_ANSWER from '@/graphql/subscriptions/saveFormAnswer.gql'
import camelcaseKeys from 'camelcase-keys'
import { SharedMethods } from '@/components/shared/mixins/sharedMixins'
import Question from '@/components/forms/partials/QuestionTree'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormBuilderMethods, FormRules } from '@/components/shared/mixins/formMixins'
import { FormMixins } from '@/components/shared/mixins/formSave'
import Loader from '@/assets/loader.svg'
import {getRandomSet} from '@/modules/forms'
import {addNode, buildQuestionTree, mapQuestionConfig} from '@/modules/formBuilder'
import GET_TENANT from '@/graphql/queries/getTenant.gql'
import {DEFAULT_FORM_TITLE, FORM_TYPES} from '@/modules/constants'
import {GET_TENANT_BY_SHORT_NAME_FOR_DYNAMIC_FORM} from '@/graphql/queries/tenantQueries.gql'
import {mergeDeep} from '@/utils/object.helpers'

export default {
    name: 'Form',
    components: { Question, Loader},
    mixins: [ SharedMethods, MessageDialog, FormBuilderMethods, FormMixins, FormRules ],
    props: {
        formProps: {
            type: Object,
            default: () => {}
        },
        formTitle: {
            type: String,
            default: ''
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        tenant: {
            query: GET_TENANT,
            variables() {
                return {
                    tenantId: this.tenantId
                }
            },
            skip() {
                // we skip this get if we don't have a tenantId OR we are getting by the shortname
                return !this.tenantId
            },
            result({data: { tenant }}) {
                this.tenant = camelcaseKeys(tenant, {deep: true})
                this.setParentTenantId(this.tenant?.parentTenantId)
                this.setFormTitle(`Thank you for helping us improve ${tenant.name}`)
                this.$store.dispatch('setTenantLogo', this.tenant.logo)
                this.setTenant(this.tenant)
            }
        },
        tenantByShortName: {
            query: GET_TENANT_BY_SHORT_NAME_FOR_DYNAMIC_FORM,
            variables() {
                return {
                    shortName: this.shortName
                }
            },
            skip() {
                // we skip this get if we don't have a shortname
                return !this.shortName
            },
            result({data: { tenantByShortName: tenantList }}) {
                this.tenant = camelcaseKeys(tenantList[0] || {}, {deep: true})
                if (!this.tenantId) this.tenantId = this.tenant.tenantId
                this.setParentTenantId(this.tenant?.parentTenantId)
                this.setFormTitle(`Thank you for helping us improve ${this.tenant.name}`)
                this.$store.dispatch('setTenantLogo', this.tenant.logo)
                this.setTenant(this.tenant)
            }
        },
        // Next 2 properties are used to get the questions for a random
        questionIds: {
            query: GET_QUESTION_IDS_BY_CATEGORY,
            variables() {
                const category = isNaN(this.category) ? null : +this.category
                let tenantIdList = [this.tenantId]
                if (this.parentTenantId) tenantIdList = [...tenantIdList, this.parentTenantId]

                //NOTE: we need the tenantId as a list here because this gql calls a postgres function
                const query = {
                    tenantId: `{${tenantIdList.join(',')}}`,
                    categoryId: category,
                    formSetId: this.formSetId,
                }
                // if (this.keywordSearch) query.keywordSearch = `%${this.keywordSearch}&`
                if (this.keywordSearch) query.keywordSearch = this.keywordSearch.toLowerCase()
                return {
                    ...query
                }
            },
            skip() {
                const skip = !this.tenantId || !this.parentTenantId || !!this.isExternal || (!this.category && !this.keywordSearch)
                if (skip) this.questionIdsByCategoryLoading = false
                return skip
            },
            async result({ data: { questionIds } }) {
                let questionIdList = camelcaseKeys(questionIds, { deep: true })
                // this.$store.commit('SET_ANSWER_SET', this.genericForm.answer)
                if (questionIdList && questionIdList.length) {
                    questionIdList = questionIdList.map(qi => qi?.questionId)
                    if (this.fullSurvey) this.questionIds = questionIdList
                    else this.questionIds = getRandomSet(questionIdList)
                    // now we have the questions, we can go and get the questions by Id
                    this.forceGetFormSet = !this.fullSurvey
                    if (this.$apollo.queries.formSetObj) await this.$apollo.queries.formSetObj.refetch()
                    if (this.fullSurvey) await this.$apollo.queries.formWithQWuestions.refetch()
                    //TODO: ssee if we still need this
                    this.getQuestionsById = true
                }
                this.questionIdsByCategoryLoading = false
            }
        },
        questions: {
            query: GET_QUESTIONS_BY_ID,
            variables() {
                // const isFullSurvey = Number(this.fullSurvey)
                //TODO: take into account full survey
                const tenantIdOrCompare = [{
                    tenant_id: {_eq: this.tenantId}
                }]

                if (this.parentTenantId) {
                    tenantIdOrCompare.push({
                        tenant_id: {_eq: this.parentTenantId}
                    })
                }

                const query = {
                    tenantIdOrCompare,
                    questionIds: this.questionIds,
                    formSetId: this.formSetId
                }
                return {
                    ...query
                }
            },
            skip() {
                const skip = !this.tenantId || !!this.isExternal || !this.questionIds.length || this.fullSurvey
                if (skip) this.questionByIdLoading = false
                return skip
            },
            result({ data: { questions } }) {
                let questionList = camelcaseKeys(questions, { deep: true })
                // questionList = mapQuestionConfig(questionList, this.$device.mobile)
                questionList = mapQuestionConfig(questionList, false)
                // this.$store.commit('SET_ANSWER_SET', this.genericForm.answer)
                this.questionByIdLoading = true
                this.$nextTick(() => {
                    const form = this.forms[0]
                    if (questionList && questionList.length) form.questions = [...questionList]
                    this.forms[0] = {...form}
                    this.questionByIdLoading = false
                })
            }
        },
        formSetObj: {
            query: GET_FORM_SET_BY_ID,
            variables() {
                const tenantIdOrCompare = [{
                    tenant_id: {_eq: this.tenantId}
                }]

                if (this.parentTenantId) {
                    tenantIdOrCompare.push({
                        tenant_id: {_eq: this.parentTenantId}
                    })
                }

                return {
                    tenantIdOrCompare,
                    formSetId: this.formSetId
                }
            },
            skip() {
                let skip = !this.tenantId || this.formSetId == null || (!!this.formAnswerId && !!this.isExternal) || this.questionByIdLoading
                if (this.forceGetFormSet) skip = false
                if (skip) this.formLoading = false
                return skip
            },
            async result({ data: { formSetObj } }) {
                const {forms, ...formSet} = camelcaseKeys(formSetObj || {}, { deep: true })[0]
                this.formSet = {...formSet}
                // let's check if the first one is actually a form
                if (!this.forms[0]?.formSetId) this.forms.shift()
                if (forms?.length) {
                    for (const formSetForm of forms) {
                        const form = formSetForm.form
                        if (!form.tenantId) form.tenatId = this.tenantId
                        form.title = formSetForm.name
                        this.forms.push(form)
                    }
                    this.setTitle(formSet.name)
                }
                // now we get the questions
                if (this.questionByIdLoading) {
                    if (this.fullSurvey) await this.$apollo.queries.questionIdsForFormAnswer.refetch()
                    else await this.$apollo.queries.questions.refetch()
                }
                this.formLoading = false
            }
        },
        formWithQuestions: {
            query: GET_FORM_WITH_QUESTIONS,
            variables() {
                const tenantIdOrCompare = [{
                    tenant_id: {_eq: this.tenantId}
                }]

                if (this.parentTenantId) {
                    tenantIdOrCompare.push({
                        tenant_id: {_eq: this.parentTenantId}
                    })
                }
                const query = {
                    tenantIdOrCompare,
                    formSetId: this.formSetId
                }
                if (this.questionIds.length) {
                    // first we filter out the questions that have already been answered
                    let filteredQuestionIds = this.questionIds.filter(q => !this.questionIdsForFormAnswer.includes(q))
                    query['questionIdExp'] = {_in: filteredQuestionIds}
                } else {
                    query['questionIdExp'] = {}
                }
                return query
            },
            skip() {
                // const category = Number(this.category)
                // so we only DON'T want to skip if we have a formAnswer but we didn't find any questions on it
                let skip = !this.tenantId ||
                    (!this.isExternal && !this.reloadQuestions) ||
                    // !!this.formAnswerId ||
                    (!!this.questionIds.length && !this.fullSurvey) ||
                    this.qIdsForAnswerLoading || this.forceGetFormSet
                if (skip) this.formWithQuestionsLoading = false
                return skip
            },
            result({ data: { formWithQuestions } }) {
                const formSetForms = camelcaseKeys(formWithQuestions, { deep: true })
                if (formSetForms.length) {
                    let title = ''
                    // since the formSet will be the same regardless of which formSetForm we choose, we get it from the first one
                    this.formSet = formSetForms[0]?.formSet
                    const isWizard = this.formSet?.formType?.type === FORM_TYPES.Wizard
                    // let currentForms = []
                    // if (this.forms.length) currentForms = [...this.forms]
                    // this.forms = []
                    for (const formSetForm of formSetForms) {
                        if (!formSetForm) continue
                        const form = formSetForm?.form

                        title = form.title = formSetForm?.formSet?.name || ''
                        if (!form.tenantId) form.tenantId = this.tenantId

                        let questionList = camelcaseKeys(form?.questions, { deep: true })
                        const questionSets = form?.questionSets || []

                        // if we don't have any questions or querstionSets we continue
                        if (!questionSets?.length && !questionList.length) continue

                        if (questionSets?.length) {
                            // this.questionSets = this.buildQuestionTree(questionSets, this.$device.mobile)
                            form.questionSets = this.buildQuestionTree(questionSets, false)
                            form.totalQuestions = this.getTotalQuestions(this.questionSets)
                        } else if (questionList && questionList.length) {
                            // questionList = mapQuestionConfig(questionList, this.$device.mobile)
                            questionList = mapQuestionConfig(questionList, false)
                            form.questions = questionList
                        }

                        // let's check if we have the form already, otherwise we just push it
                        const idx = this.forms.findIndex(f => f.formId === form.formId && f.version === form.version)
                        if (idx > -1) this.forms[idx] = mergeDeep(form, this.forms[idx])
                        else this.forms.push(form)
                    }
                    // this.forms = [...forms]

                    this.setTitle(title)
                    this.allForms = [...this.forms]
                    if (isWizard) {
                        this.forms = [this.allForms[0]]
                    }
                }
                this.formWithQuestionsLoading = false
            }
        },
        //TODO: check if this is deprecated as we don't need to worry about this for the moment
        // This was used for the full survey that's not used at the moment
        questionIdsForFormAnswer: {
            query: GET_ANSWERED_QUESTION_IDS,
            variables() {
                return {
                    formAnswerId: this.formAnswerId,
                    tenantId: this.tenantId
                }
            },
            skip() {
                const skip = !this.tenantId || !this.formAnswerId || this.reloadQuestions || !this.qIdsForAnswerLoading
                if(skip) {
                    // this.formWithQuestionsLoadingAfterQIds = false
                    this.qIdsForAnswerLoading = false
                }

                // return skip
                return skip
            },
            result({ data: { questionIdsForFormAnswer } }) {
                const questions = camelcaseKeys(questionIdsForFormAnswer, { deep: true })
                this.questionIdsForFormAnswer = questions.reduce((curr, q) => {
                    curr.push(Number(q.questionId))
                    return curr
                }, [])
                this.$apollo.queries.formWithQuestions.refetch()
                this.qIdsForAnswerLoading = false
            }
        },
        formAnswer: {
            query: GET_ANSWER,
            variables() {
                return {
                    formAnswerId: this.formAnswerId,
                    tenantId: this.tenantId
                }
            },
            skip() {
                const skip = !this.tenantId || !this.formAnswerId || this.reloadQuestions
                if(skip) {
                    this.answerLoading = false
                    // if we are skipping this step, we want to get the questions anyway
                    this.reloadQuestions = true
                }
                return skip
            },
            async result({ data: { formAnswer } }) {
                const answerResponse = camelcaseKeys(formAnswer, { deep: true })
                if (answerResponse.length) {
                    const answer = answerResponse[0] || []

                    if (answer?.answer) {
                        // we so have an answer, now we need to determine which index to use,
                        // we are getting it from the query string for now:
                        let title = ''
                        let forms = []
                        if (this.formAnswerIndex != null) {
                            forms = [{...answer?.answer[Number(this.formAnswerIndex)]}]
                        } else {
                            forms = [...answer?.answer]
                        }
                        this.totalQuestions = 0
                        for (const form of forms) {
                            if (form.questionSets) this.totalQuestions += this.getTotalQuestions(form.questionSets)
                            // cause of how the title is set when the answer is saved, should be the same for all instances
                            title = form.title || DEFAULT_FORM_TITLE
                        }
                        // let's also get the data from the formanswer if we knw we have a formId and version
                        if (title) this.setTitle(title)
                        if (this.isExternal) {
                            this.forms = [...forms]
                            this.allForms = [...this.forms]
                        }
                    }
                    // now we have to check both the questionSets and questions
                    // since the form we're loading might only have questions
                    if (!this.totalQuestions) {
                        this.reloadQuestions = true
                        // await this.$apollo.queries.questionIdsForFormAnswer.refetch()
                        await this.$apollo.queries.formWithQuestions.refetch()
                    }
                    this.$emit('update-form-answer-status', answer?.status)
                    this.formAnswer = {...(answer || {})}
                    if (!this.formAnswerId) this.formAnswerId = this.formAnswer.formAnswerId
                } else {
                    //let's try loading the questions
                    this.reloadQuestions = true
                    await this.$apollo.queries.questionIdsForFormAnswer.refetch()
                }
                this.answerLoading = false
            }
        },
    },
    data() {
        return {
            title: 'Patient Survey',
            totalProgress: 33,
            tenantId: this.$route.query.tenant || this.$route.query?.ti,
            formVersion: this.$route.query.v,
            formId: this.$route.query.f,
            formSetId: this.$route.query.fsi,
            // TODO: use below when we want to get the random questions
            category: this.$route.query.category,
            change: this.$route.query.change,
            fullSurvey: this.$route.query.fs,
            formAnswerId: this.$route.params?.formAnswerId || this.$route.query.fai,
            isExternal: this.$route.query.ext,
            formAnswerIndex: this.$route.query.faidx,
            keywordSearch: this.$route.query.ks,
            shortName: this.$route.params?.shortName || '',
            isKiosk: !!this.$route.params?.kiosk,
            foundEpisode: null,
            forms: [{
                ...(this.formProps || {}),
                questions: []
            }],
            //allForms stores all forms in the form set, depending on what type of form we are looking at, the 'form's
            // list is what is displayed
            allForms: [],
            formSet: {},
            form: {
                ...(this.formProps || {}),
                questions: []
            },
            questionSets: [],
            questionIds: [],
            questionIdsForFormAnswer: [],
            totalQuestions: 0,
            totalComplete: 0,
            defaultButtonText: 'Next',
            isValid: true,
            loading: false,
            disabled: false,
            reloadQuestions: false,
            // first we define savedFormAnswerId so we can watch it for changes
            emptyQuestionText: 'No further questions were found for this survey, you can close the browser at any time.',
            answerLoading: true,
            formWithQuestionsLoading: true,
            formWithQuestionsLoadingAfterQIds: true,
            formLoading: true,
            questionByIdLoading: true,
            questionIdsByCategoryLoading: true,
            qIdsForAnswerLoading: true,
            saving: false,
            hasPhoneOrEmail: false,
            saveTimeout: null,
            saveTimeoutDelay: 1000,
            formAnswer: {},
            forceGetFormSet: false,
            getQuestionsById: false,
        }
    },
    beforeMount() {
        //resetting formAnswer before we do anything
        this.formAnswer = {}
    },
    async mounted() {
        const that = this
        this.setPreviousRoute(this.$route.name)
        this.setFormTitle(this.title)
        this.setIsFullSurvey(this.fullSurvey)

        // we skip the timeout if the form is showing in an iframe or it's the full survey
        if (this.isExternal || this.fullSurvey || !this.isKiosk) return

        this.startIdleTimeout()
        this.$root.$on('reset-time-out', () => {
            that.resetIdleTimeout()
        })
    },
    methods: {
        ...mapActions([
            'setFormTitle', 'setIsFullSurvey', 'setParentTenantId',
            'setPreviousRoute', 'setTenant', 'clearSaveTimeoutQueue',
            'updateIsSaving'
        ]),
        setTitle(title) {
            this.title = title
            this.setFormTitle(this.title)
            this.$emit('update:form-title', this.title)
        },
        callEditOrFunction(element, index) {
            if (!this.editingForm) {
                if (!this.validate()) return
                this.updateIsSaving(true)
                // this.loading = true

                this.functionCallHandler(element.click)
                .then((resp) => {
                    if (resp?.data?.formAnswer) console.log('successfully saved form answer')
                })
                .catch((e) => {
                    console.log('error', e)
                })
                .finally(() => {
                    this.disabled = false
                    this.updateIsSaving(false)
                    // this.loading = false
                })
            } else {
                this.editElement(element, index)
            }
        },
        editElement(element, index) {
            // we only bubble this up if we are editing the form
            if (this.editingForm) this.$root.$emit('edit-element', { element, index })
        },
        validate() {
            let questionTreeIsValid = true
            if (this.$refs.questionTree instanceof Array) {
                for (const component of this.$refs.questionTree) {
                    questionTreeIsValid = component?.validate()
                    if (!questionTreeIsValid) break
                }
            } else {
                questionTreeIsValid = this.$refs.questionTree?.validate()
            }

            this.isValid = this.$refs.form?.validate() && questionTreeIsValid

            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.form.reset()
            return this.isValid
        },
        buildQuestionTree: buildQuestionTree,
        addNode: addNode,
        updateNumberComplete(questionSets) {
            this.totalComplete = this.getNumberComplete(questionSets)
        },
        getButtonText(action) {
            let btnText = ''
            // if we have multiple forms, let's check each of them to see if we actually have questions
            let haveQuestions = false
            for (const form of this.forms) {
                if (form.questions?.length) {
                    haveQuestions = true
                    break
                }
            }

            if (!haveQuestions) btnText = 'Finish'
            else btnText = action.buttonText || this.defaultButtonText
            return this.isSaving || (this.loading && this.isQpa) ? 'Please wait...' : btnText
        },
        async callSaveForm(formIdx, questionSets = []) {

            // if we aren't an external form, we ignore the automatic save for now
            if (!this.isExternal && !this.fullSurvey) {
                if (this.isKiosk) this.resetIdleTimeout()
                return
            }

            this.forms[formIdx].questionSets = questionSets;

            //TODO: need to change this
            if (!this.editingForm) {
                if (!this.isExternal && !this.validate()) return
                // this.loading = true
                if (this.saveTimeout) clearTimeout(this.saveTimeout)
                const callback = async () => {
                    // if we are already saving, let's reset the timeout and try again
                    if (this.isSaving) {
                        //let's add it to the queue and try again
                        await this.$store.dispatch('addToSaveTimeoutQueue', callback)
                        return
                    }
                    await this.save()
                    // ok we are done, now let's check for any more callbacks
                    const latestCallbackOnTheQueue = this.saveTimeoutQueue?.at(-1)
                    // now let's clear it before we add anymore on
                    this.clearSaveTimeoutQueue()
                    if (latestCallbackOnTheQueue) await latestCallbackOnTheQueue.call(this)
                    this.$emit('update-form-stats')
                    // this.loading = false
                }
                this.saveTimeout = setTimeout(callback, this.saveTimeoutDelay)
            }
        },
        getFormClasses(form) {
            return form?.cssClasses || ''
        },
        hasQuestions(form) {
            return form?.questions?.length || form?.questionSets?.length
        },
        showBtnRow(idx) {
            return idx === this.forms.length - 1
        }
    },
    computed: {
        ...mapState({
            answerSet: state => state.app.answerSet,
            messageResult: state => state.app.messageResult,
        }),
        ...mapGetters(['editingForm', 'parentTenantId', 'saveTimeoutQueue', 'isSaving']),
        btnRowClasses() {
            let classes = this.isExternal ? 'flex flex-row justify-center' : 'grid w-full justify-items-end'
            classes += this.$device.mobile ? ' mt-0' : ' mt-8'
            return classes
        },
        isLoading() {
            return this.answerLoading || this.formWithQuestionsLoading || this.formLoading || this.questionByIdLoading
        },
        isNps() {
            return this.allForms.findIndex(f => f?.formType?.type.toLowerCase() === 'nps') > -1
        },
        routeType() {
            return this.isNps ? 'nps' : ''
        },
        isQpa() {
            return this.tenant?.parentTenant?.name?.toLowerCase().indexOf('qpa') > -1
        }
    },
    watch: {
        messageResult() {
            if (this.messageResult && Object.keys(this.messageResult).length) {
                this.message = this.messageResult.message
                this.type = this.messageResult.type
                this.showMessage({ duration: 5000 })
            }
        },
        async tenantId() {
            if (this.tenantId) {
                // await this.$apollo.queries.questionIds.refetch()
                // this.isExternal = false
                // await this.$apollo.queries.formSetObj.refetch()
                this.reloadQuestions = true
                await this.$apollo.queries.formWithQuestions.refetch()
            }
        },
        qIdsForAnswerLoading() {
            if (!this.qIdsForAnswerLoading && !this.formWithQuestionsLoadingAfterQIds) {
                // this.formWithQuestionsLoadingAfterQIds = true
                this.$apollo.queries.formWithQuestions.refetch()
            }
        },
        // formWithQuestionsLoadingAfterQIds() {
        //     if (!this.qIdsForAnswerLoading && !this.formWithQuestionsLoadingAfterQIds) {
        //         this.formWithQuestionsLoadingAfterQIds = true
        //         this.$apollo.queries.formWithQuestions.refetch()
        //     }
        // },
        isLoading() {
            // need to pass to the parent when the form has finished loading (this is for ted but can be subscribed to by other apps)
            if (!this.isLoading) parent.postMessage('formLoaded', '*')
        },
    },
    destroyed() {
        this.$root.$off('reset-time-out')
        this.cancelIdleTimeout()
    }
}
</script>
