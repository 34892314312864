<template lang="pug">
    //.question-tree
    .question-tree(v-if="showQuestion")
        QuestionGroup(
            v-if="haveGrouping"
            ref="questionGroup"
            :question-sets="questionSets"
            :answer-sets="answerSets"
            :disabled="disabled"
            @question-sets-updated="questionSetsUpdated"
            @save-form="saveForm"
        )
        QuestionOnly(
            v-else-if="formQuestions.length && !questionSets.length"
            ref="questionOnly"
            :questions="formQuestions"
            :disabled="disabled"
            :form="form"
            @save-form="saveForm"
        )
        QuestionPanel(
            v-else
            ref="questionPanel"
            :question-sets="questionSets"
            :answer-sets="answerSets"
            :is-child="isChild"
            :first-child-open="firstChildOpen"
            :disabled="disabled"
            @question-sets-updated="questionSetsUpdated"
            @save-form="saveForm"
        )
</template>

<script>
import QuestionPanel from '@/components/forms/partials/QuestionPanel'
import QuestionGroup from '@/components/forms/partials/QuestionGroup'
import QuestionOnly from '@/components/forms/partials/QuestionOnly'
import {VALUE_MAPPING} from '@/modules/constants'

export default {
    name: 'Question',
    components: { QuestionGroup, QuestionPanel, QuestionOnly },
    props: {
        questionSets: {
            type: Array,
            default: () => []
        },
        questions: {
            type: Array,
            default: () => []
        },
        answerSets: {
            type: Array,
            default: () => []
        },
        isChild: {
            type: Boolean,
            default: false
        },
        firstChildOpen: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dependsOn: {
            type: Number,
            default: null
        },
        dependsOnValue: {
            types: [String, Boolean, Number]
        },
        form: {
            type: Object,
            default: () => {}
        },
        questionSetIdxs: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            questionTypes: ['questionGroup', 'questionOnly', 'questionPanel'],
        }
    },
    methods: {
        questionSetsUpdated(questionSets) {
            this.$emit('question-sets-updated', questionSets)
        },
        saveForm(questionSets) {
            if (this.disabled) return
            this.$nextTick(() => {
                this.$emit('save-form', questionSets)
            })
        },
        validate() {
            const isValidList = []
            for (let qType of this.questionTypes) {
                //TODO: fix up the validate functionality so that each of the types has a validate function in it
                // have to check if qType is in this.$refs and if that ref is defined, for some reason, questionPanel is undefined
                if (qType in this.$refs && this.$refs[qType]) isValidList.push('validate' in this.$refs[qType] && this.$refs[qType]?.validate())
            }
            //TODO: check to see if we need a form on this page, I suspect we don't
            // this.isValid = this.$refs.form.validate() && isValid
            this.isValid = isValidList.indexOf(false) === -1
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
        checkForValue(value) {
            if (typeof value === 'string') {
                const upperCaseValue = value.toUpperCase()

                if (upperCaseValue in VALUE_MAPPING) {

                    return VALUE_MAPPING[upperCaseValue]
                }
            }
            return value
        },
    },
    computed: {
        haveGrouping() {
            return this.questionSets && this.questionSets.find(qs => qs.isGrouping)
        },
        formQuestions() {
            return this.questions || []
        },
        showQuestion() {
            // if we don't depend on anything, then display the question and return
            if (!this.dependsOn) return true

            // for now we just show if we have a value or if it's true
            return this.dependsOnValue != null && this.checkForValue(this.dependsOnValue)
        }
    },
}
</script>
