<template>
    <div
        v-if="isGrid"
    >
        <div>
            <QuestionDisplay
                :class="`col-span-${gridLayout.totalCols}`"
                :key="`${uniqueId}-form-${gridLayout.formTitle.questionId}`"
                :index="null"
                :question="getQuestion(gridLayout.formTitle.questionId)"
                :render-question="renderQuestion(getQuestion(gridLayout.formTitle.questionId))"
                @question-updated="questionUpdated"
                @value-changed="valueChanged"
                @edit-element="editElement"
            />
        </div>
        <div :class="gridClasses">
            <div
                v-for="(idList, key) in gridLayout.columns"
                :key="`grid-col-${key}`"
                class="align-center"
            >
                <QuestionDisplay
                    v-for="(question, idx) in idList"
                    ref="formInput"
                    :class="`col-span-${question.span || 1}`"
                    :key="`${uniqueId}-form-${question.questionId}-${idx}`"
                    :parent-idx="idx"
                    :question="getQuestion(question.questionId)"
                    :render-question="renderQuestion(getQuestion(question.questionId))"
                    @question-updated="questionUpdated"
                    @value-changed="valueChanged"
                    @edit-element="editElement"
                />
            </div>
        </div>
    </div>
    <div v-else>
        <QuestionDisplay
            v-for="(question, idx) in formQuestions"
            ref="formInput"
            :key="`${uniqueId}-form-${question.questionId}-${idx}`"
            :parent-idx="idx"
            :question="question"
            :render-question="renderQuestion(question)"
            @question-updated="questionUpdated"
            @value-changed="valueChanged"
            @edit-element="editElement"
        />
    </div>
</template>

<script>
import {FormRules, FormBuilderMethods, FormConditionalChecks} from '@/components/shared/mixins/formMixins'
import {mapActions, mapGetters} from 'vuex'
import {VALUE_MAPPING} from '@/modules/constants'
import QuestionDisplay from '@/components/forms/partials/QuestionDisplay'

export default {
    name: 'QuestionOnly',
    components: {
        QuestionDisplay
    },
    mixins: [FormRules, FormBuilderMethods, FormConditionalChecks],
    props: {
        questions: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        form: {
            type: Object,
            default: () => {}
        },
        questionSetIdxs: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isValid: true,
            gridLayout: {totalCols: 0, formTitle: {}, columns: {}},
            // because the panel only renders either the grid or the questionDisplay, we can use just the 1 unique id
            uniqueId: null
        }
    },
    beforeMount() {
        this.uniqueId = this.$uuid.v4()
        this.setMapping()
    },
    mounted() {
        // subscribe to all the questionSets conditional show/hide
        this.subscribeToDependencies(this.formQuestions)
    },
    methods: {
        ...mapActions(['setQuestionMapping', 'updateQuestionMapping']),
        editElement(element, index) {
            this.$root.$emit('edit-element', {element, index})
        },
        valueChanged(element, index) {
            // //let's update the formQuestions here too
            // console.log('Value has changed')
            if (this.formQuestions.length < index) this.formQuestions[index] = element

            if (!this.editingForm) {
                // this.$nextTick(() => {
                    this.$emit('save-form', this.formQuestions, this.questionSetIdxs)
                // })
            }
            else this.$root.$emit('edit-element', {element, index})
            // now we emit if we have changed something so that other questions might be shown
            this.$root.$emit(`edit-${this.formQuestions[index].questionId}`, {
                questionId: this.formQuestions[index]?.questionId,
                value: this.formQuestions[index]?.value,
                componentType: this.formQuestions[index]?.config?.is
            })
        },
        validate() {
            // this.isValid = this.$refs.formInput.validate()
            const validList = [];
            if (this.$refs.formInput instanceof Array) {
                for (const form of this.$refs.formInput) {
                    if (!form || !form.validate) continue

                    validList.push(form.validate())
                }
            } else {
                if (this.$refs.formInput.validate) validList.push(this.$refs.formInput.validate())
            }
            for (let i = 0; i < this.formQuestions.length; i++) {
                const component = this.$refs[`question-${i}`]
                if (component?.length && component[0].validate) validList.push(component[0].validate())
            }
            this.isValid = validList.indexOf(false) === -1
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            this.$refs.formInput.reset()
            return this.isValid
        },
        checkForValue(value, question, key = '', componentType = '') {
            let dependsOnValueIn = []
            if (!componentType) return
            if (question?.config?.dependsOnValueIn) dependsOnValueIn = question.config.dependsOnValueIn.split('|')

            if (dependsOnValueIn?.length) {
                this.updateQuestionMapping({ questionId: question.questionId, value: dependsOnValueIn.indexOf(String(value)) > -1 })
                return
            } else if (value && componentType.toLowerCase().indexOf('select') > -1 && (value instanceof Array || value.indexOf('[') === 0)) {
                // we should only get here if the component Type has select in the string and if the value is either an
                // instance of an array OR the value has a '[' at the beginning of a string (a JSON representation of an array)
                const mappedValue = value instanceof Array ?
                    value.map((v) => typeof v === 'string' ? v.toUpperCase().replace(/[\s,]/g, '_') : v) :
                    value.toUpperCase().replace(/[\s,]/g, '_')
                this.updateQuestionMapping({ questionId: question.questionId, value: mappedValue.indexOf(question.name.replace(/[\s,]/g, '_').toUpperCase()) > -1 })
                return
            } else if (typeof value === 'string') {
                const upperCaseValue = value.toUpperCase()

                if (upperCaseValue in VALUE_MAPPING) {
                    this.updateQuestionMapping({ questionId: question.questionId, value: VALUE_MAPPING[upperCaseValue] })
                    return
                }
            }
            this.updateQuestionMapping({ questionId: question.questionId, value: !!value })
        },
        setMapping() {
            for (let question of this.formQuestions) {
                // show questions that don't have dependencies, hide ones that do on first load
                this.updateQuestionMapping({ questionId: question.questionId, value: true })
                this.addToGrid(question)
                // now we check if we have to show the question
                if (question.dependsOnQuestionId) {
                    const formQuestion = this.formQuestions.find(q => q.questionId === question.dependsOnQuestionId)
                    // if (formQuestion?.value) this.checkForValue(formQuestion.value, question, null, formQuestion?.config?.is)
                    this.checkForValue(formQuestion?.value, question, null, formQuestion?.config?.is)
                }
            }
            this.setQuestionMapping(this.questionMapping)
        },
        showQuestion(event) {
            const { questionId, value } = event
            if (!questionId) return
            const questions = this.formQuestions.filter(qs => qs.dependsOnQuestionId === questionId)
            if (!questions?.length) return
            // changing the value into a boolean
            for (const question of questions) {
                this.checkForValue(value, question, null, question?.config?.is)
            }
            this.setQuestionMapping(this.questionMapping)
        },
        renderQuestion(question) {
            if (!question.questionId) return false

            return this.questionMapping[question.questionId]
        },
        // functions to generate the grid layout if we find it
        // we only add id's, there's a function that gets the question from the list to pass to the component
        addToGrid(question) {
            if (!question?.config?.layout) return

            const { col, span } = question?.config?.layout
            if (col in this.gridLayout.columns) this.gridLayout.columns[col].push({questionId: question.questionId, span})
            else if (col) this.gridLayout.columns[col] = [{questionId: question.questionId, span}]
            else this.gridLayout.formTitle = {questionId: question.questionId, span}
            // we minus 1 because we ignore the gridlayout
            this.gridLayout.totalCols = Object.keys(this.gridLayout.columns).length
        },
        getQuestion(questionId) {
            const question = this.formQuestions.find(fq => fq.questionId === questionId)
            return question || {}
        },
        questionUpdated({ question, index }) {
            this.formQuestions[index] = question
        }
    },
    computed: {
        ...mapGetters(['editingForm', 'questionMapping']),
        formQuestions() {
            return this.questions || []
        },
        isGrid() {
            // we can look at any of the questions as we either have them all in a grid or nonw
            // console.log(this.form.formId, this.form.version, this.formQuestions)
            const firstLayout = this.formQuestions.find(fq => fq?.config?.layout && fq.formId === this.form.formId && fq.formVersion === this.form.version)
            return firstLayout?.config?.layout?.type === 'grid'
        },
        gridClasses() {
            return this.isGrid ? `grid grid-cols-1 flex items-center md:grid-cols-${this.gridLayout.totalCols}` : ''
        }
    },
    destroyed() {
        this.unsubscribe(this.questions)
    }
}
</script>
