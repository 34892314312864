<template>
    <div class="mb-4">
        <h2
            class="text-center text-lg lg:text-xl max-w-3/4 m-auto mt-4"
        >{{finalTitle}}</h2>
<!--        <div-->
<!--            v-if="subTitle"-->
<!--            class="text-center text-grey-2 text-sm mt-5 mb-2"-->
<!--        >{{subTitle}}</div>-->
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {ref} from '@vue/composition-api'
const { useState } = createNamespacedHelpers('app')

export default {
    name: 'FormTitle',
    props: {
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
    },
    setup(props) {
        const propTitle = ref(props.title)
        const { category } = useState(['category'])

        let finalTitle = ref(category.value)
        if (propTitle.value) finalTitle.value = propTitle.value

        return {
            finalTitle
        }
    }
}
</script>
