<template>
    <v-form
        :key="`form-${questionObj.questionId}`"
        ref="form"
        :class="getFormClass(questionObj)"
        v-model="isValid"
        lazy-validation
        @submit.prevent
    >
        <!--
            Note: we need all the events below as `event` is used for Ted and the click-triggered
                  and value-changed are used for all other form-builder apps
        -->
        <component
            v-if="renderQuestionVal && haveComponent"
            :key="`${uniqueId}-question-${questionObj.questionId}`"
            ref="questionRef"
            :is="questionObj.config.is"
            :rules="getRulesFromConfig(questionObj.config)"
            :config="questionObj.config"
            :editing-form="editingForm"
            :class="{'disabled pointer-events-none': disabled}"
            v-bind="questionAttributes"
            v-model="questionObj.value"
            @click-triggered="editElement(questionObj)"
            @value-changed="valueChanged"
            @change="valueChanged"
            @blur="valueChanged"
        ></component>
    </v-form>
</template>

<script>
import RadioGroup from '@/components/basic/RadioGroup'
import FormTitle from '@/components/basic/FormTitle'
import FormSubtitle from '@/components/basic/FormSubtitle'
import SelectAndLabel from '@/components/basic/SelectAndLabel'
import CompleteFullSurvey from '@/components/shared/CompleteFullSurvey'
import FaceButtonGroup from '@/components/basic/FaceButtonGroup'
import TextareaAndLabel from '@/components/basic/TextareaAndLabel'
import TextFieldAndLabel from '@/components/basic/TextFieldAndLabel'
import PhoneNumber from '@/components/basic/PhoneNumber'
import NetPromoterScore from '@/components/forms/net-promoter-score/NetPromoterScore'
import NetPromoterButtonGroup from '@/components/basic/NetPromoterButtonGroup'
import CheckboxGroup from '@/components/basic/CheckboxGroup'
// import {FormBuilderMethods, FormConditionalChecks, FormRules} from '@/components/shared/mixins/formMixins'
import {onMounted, ref, watch, computed} from '@vue/composition-api'
import {useFormFns} from '@/composables/form-fns'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import LogoTitle from '@/components/basic/LogoTitle'
import { uuid } from 'vue-uuid'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'QuestionDisplay',
    emits: ['value-changed', 'question-updated'],
    components: {
        RadioGroup,
        FormTitle,
        FormSubtitle,
        SelectAndLabel,
        CompleteFullSurvey,
        FaceButtonGroup,
        TextareaAndLabel,
        TextFieldAndLabel,
        PhoneNumber,
        NetPromoterScore,
        NetPromoterButtonGroup,
        CheckboxGroup,
        LogoTitle,
    },
    // mixins: [FormRules, FormConditionalChecks],
    props: {
        question: {
            type: Object,
            required: true
        },
        editingForm: {
            type: Boolean,
            default: false
        },
        parentIdx: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        renderQuestion: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const uniqueId = ref(uuid.v4())
        const questionObj = ref({config: {}})
        const { getRulesFromConfig } = useFormFns()
        const form = ref({})
        const questionRef = ref({})
        const isValid = ref(true)
        const { questionMapping } = useGetters(['questionMapping'])

        //#region life cycle hooks
        onMounted(() => {
            questionObj.value = {...props.question}
        })
        //#endregion

        //#region functions
        const valueChanged = async () => {
            await emit('question-updated', {question: questionObj.value, index: questionIndex.value})
            await emit('value-changed', questionObj.value, questionIndex.value)
        }

        const editElement = (element) => {
            emit('edit-element', element, questionIndex.value)
        }

        const validate = () => {
            isValid.value = form.value.validate()
            if (questionRef.value && 'validate' in questionRef.value) isValid.value = questionRef.value?.validate()
            return isValid.value
        }

        const getFormClass = (questionObj) => {
            return questionObj?.config?.class || ''
        }
        //#endregion

        //#region computed properties
        const questionIndex = computed(() => props.parentIdx)
        const renderQuestionVal = computed(() => props.renderQuestion || questionMapping.value[questionObj.value.questionId])
        const questionAttributes = computed(() => ({...questionObj.value?.config, ...questionObj.value?.config?.vuetifyAttrs}))
        const haveComponent = computed(() => {
            return questionObj.value?.config?.is
        })
        // const renderQuestion = computed(() => questionMapping.value[questionObj.value.questionId])
        //#endregion

        //#region watches
        watch(questionObj, (newValue) => {
            emit('question-updated', {question: newValue, index: questionIndex.value})
        })

        watch(() => props.question, (newQuestion) => {
            questionObj.value = {...newQuestion}
        })
        //#endregion
        return {
            form,
            questionRef,
            questionObj,
            renderQuestionVal,
            isValid,
            questionAttributes,
            haveComponent,
            getRulesFromConfig,
            uniqueId,
            validate,
            valueChanged,
            editElement,
            getFormClass,
        }
    }
}
</script>
