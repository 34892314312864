<template>
    <img src="@/assets/images/tenant-logo.png" class="m-auto w-w-75p max-w-340 h-auto mb-10" id="logoTitle"/>
</template>

<script>

export default {
    name: 'LogoTitle',
    components: {},
    setup() {

    }
}
</script>
